export default (color: string) => `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="68px" height="95px" viewBox="0 0 68 95" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 54.1 (76490) - https://sketchapp.com -->

    <desc>Created with Sketch.</desc>
    <g id="Coriolis" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="About/Add-Licence-Validated" transform="translate(-254.000000, -97.000000)" stroke="${color}" >
            <g id="Modal-Connection">
                <g id="Icon/Licence/96" transform="translate(240.000000, 96.000000)">
                    <path d="M25.2555408,2 L70.7444592,2 C74.3105342,2 75.6036791,2.37130244 76.9073828,3.06853082 C78.2110865,3.76575919 79.2342408,4.78891348 79.9314692,6.09261719 C80.6286976,7.39632089 81,8.68946584 81,12.2555408 L81,84.092944 C81,87.659019 80.6286976,88.952164 79.9314692,90.2558677 C79.2342408,91.5595714 78.2110865,92.5827257 76.9073828,93.279954 C75.6036791,93.9771824 74.3105342,94.3484848 70.7444592,94.3484848 L25.2555408,94.3484848 C21.6894658,94.3484848 20.3963209,93.9771824 19.0926172,93.279954 C17.7889135,92.5827257 16.7657592,91.5595714 16.0685308,90.2558677 C15.3713024,88.952164 15,87.659019 15,84.092944 L15,12.2555408 C15,8.68946584 15.3713024,7.39632089 16.0685308,6.09261719 C16.7657592,4.78891348 17.7889135,3.76575919 19.0926172,3.06853082 C20.3963209,2.37130244 21.6894658,2 25.2555408,2 Z" id="Rectangle" stroke-width="1.5"></path>
                    <path d="M26,17.3787879 L60.5238095,17.3787879" id="Stroke-5" stroke-width="1.5"></path>
                    <path d="M26,25.3787879 L54.3095238,25.3787879" id="Stroke-5-Copy" stroke-width="1.5"></path>
                    <path d="M26,34.3787879 L69.5,34.3787879" id="Stroke-5-Copy-2" stroke-width="1.5"></path>
                    <path d="M26,42.3787879 L61.2142857,42.3787879" id="Stroke-5-Copy-3" stroke-width="1.5"></path>
                    <path d="M26,50.3787879 L43.2619048,50.3787879" id="Stroke-5-Copy-4" stroke-width="1.5"></path>
                    <g id="Group" stroke-width="1" fill-rule="evenodd" transform="translate(44.000000, 63.000000)" fill="none">
                        <path fill="none" d="M19.4400126,2.85993451e-13 C19.394621,2.85993451e-13 19.3486476,0.000558850024 19.3026741,0.00111770005 C15.9151865,0.0631500527 11.7193808,2.3594648 8.35400703,5.99422536 C3.1915947,11.5687544 1.46089756,18.5739394 4.49572794,21.6096127 C5.43323739,22.5473631 6.77461496,23.0302095 8.3685556,22.998355 C11.7566251,22.9357638 15.9518489,20.6400079 19.3172227,17.0052474 C24.479635,11.4307184 26.2103322,4.42553334 23.1755018,1.38986001 C22.2653436,0.478934471 20.9757589,2.85993451e-13 19.4400126,2.85993451e-13" id="Fill-14" stroke-width="1.5" fill="#FFFFFF"></path>
                        <path fill="none" d="M13.9163663,7 C6.24259759,7 -1.73329949e-13,9.08954024 -1.73329949e-13,11.6585738 C-1.73329949e-13,14.2270485 6.24259759,16.3165888 13.9163663,16.3165888 C21.5901349,16.3165888 27.8333333,14.2270485 27.8333333,11.6585738 C27.8333333,9.08954024 21.5901349,7 13.9163663,7" id="Combined-Shape" stroke-width="1.5"></path>
                        <path fill="none" d="M8.23123884,1.20792265e-13 C6.69549246,1.20792265e-13 5.40590778,0.478934471 4.49574963,1.38986001 C1.46091925,4.42553334 3.19161639,11.4307184 8.35344678,17.0052474 C11.7194025,20.6400079 15.9146263,22.9363227 19.3026958,22.998355 C20.9001281,23.0229444 22.238596,22.5473631 23.1755235,21.6096127 C26.2103538,18.5739394 24.4796567,11.5687544 19.3172444,5.99422536 C15.9518706,2.3594648 11.7566468,0.0631500527 8.36857729,0.00111770005 C8.32260382,0.000558850024 8.27663036,1.20792265e-13 8.23123884,1.20792265e-13" id="Fill-3" stroke-width="1.5"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`;
