/*
Copyright (C) 2022 Cloudbase Solutions SRL
This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as
published by the Free Software Foundation, either version 3 of the
License, or (at your option) any later version.
This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.
You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/

import type { SchemaProperties, SchemaDefinitions } from "@src/@types/Schema";
import OptionsSchemaParserBase from "../default/OptionsSchemaPlugin";

export default class OptionsSchemaParser extends OptionsSchemaParserBase {
  override parseSchemaToFields(opts: {
    schema: SchemaProperties;
    schemaDefinitions?: SchemaDefinitions | null | undefined;
    dictionaryKey?: string;
    requiresWindowsImage?: boolean;
  }) {
    const { schemaDefinitions } = opts;
    if (schemaDefinitions?.azure_image?.required) {
      schemaDefinitions.azure_image.required = [];
    }
    return super.parseSchemaToFields(opts);
  }
}
