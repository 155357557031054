export default `<?xml version="1.0" encoding="UTF-8"?>
<svg width="10px" height="7px" viewBox="0 0 10 7" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 47.1 (45422) - http://www.bohemiancoding.com/sketch -->

    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="symbol" transform="translate(-11.000000, -53.000000)" stroke-width="1.5" stroke="#0044CA">
            <g id="Icon/Check" transform="translate(8.000000, 48.000000)">
                <polyline id="Stroke-3" points="12.1224889 5.68533333 7.20337778 10.6044444 4.11893333 7.65688889"></polyline>
            </g>
        </g>
    </g>
</svg>`;
