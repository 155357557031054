export default `<?xml version="1.0" encoding="UTF-8"?>
<svg width="96px" height="96px" viewBox="0 0 96 96" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 47.1 (45422) - http://www.bohemiancoding.com/sketch -->

    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Coriolis" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="EP/Validate/Succes" transform="translate(-240.000000, -96.000000)" stroke-width="1.5" stroke="#39DA55">
            <g id="Modal-Connection">
                <g id="Group-Copy" transform="translate(32.000000, 95.967742)">
                    <g id="Icon/Ok/Green-96" transform="translate(208.000000, 0.032258)">
                        <circle class="circle" cx="48" cy="48" r="47.25"></circle>
                        <polyline class="path" stroke-linecap="round" stroke-linejoin="round" points="69 35 43.1861395 61 27 45.4206722"></polyline>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`;
